import React from "react"
import { Layout } from "../../components/layout/Layout"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { Text } from "@chakra-ui/core"
import { Seo } from "../../components/seo/Seo"

type Props = {
  data: any
}

// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={data.markdownRemark.frontmatter.metaTitle}
        desc={data.markdownRemark.frontmatter.metaDesc}
      />
      <Text>{data.markdownRemark.frontmatter.title}</Text>
      <TextContainer>{renderAst(data.markdownRemark.htmlAst)}</TextContainer>
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    markdownRemark(frontmatter: { path: { eq: $filter } }) {
      htmlAst
      html
      timeToRead
      frontmatter {
        title
        sidebar
        metaTitle
        metaDesc
      }
    }
  }
`
export default Template
